/**
 * Application entry point
 */
require("babel-core/register");
require("babel-polyfill");
// Load application styles
import 'styles/index.scss';

// ================================
// START YOUR APP HERE
// ================================

import Siema from 'siema';

/**
 * Header slider
 */
window.onload = () => {

    if (window.setSliderHeight) {
      window.setSliderHeight(1);
    }
    if (window.addTopOffersToIndexPage) {
      window.addTopOffersToIndexPage();
    }
    
    (() => {
      // debugger;
    const headerSlider = new Siema({
      selector: '.header-slider',
      loop: true,
      onChange: changePagination
    });

    (function addPagination() {
      const container = document.querySelector('.header').appendChild(document.createElement('div'));
      container.classList.add('slider-pagination-container');
      const quantity = document.querySelectorAll('.header-slider .header-slide').length - 2;
      for (let i = 0; i < quantity; i++) {
        i === 0 ?
        container.innerHTML += `<div class="pagination-bullet active" data-goto="${i}"></div>` :
        container.innerHTML += `<div class="pagination-bullet" data-goto="${i}"></div>`;
      }
      document.querySelectorAll('.pagination-bullet').forEach(elem => elem.style.width = `${window.innerWidth / quantity}px`);
      initListener();
    })();

    function changePagination() {
      const bullets = document.querySelectorAll('.pagination-bullet');
      return changePaginationStyle(bullets, this.currentSlide);
    }

    function changePaginationStyle(bullets, current) {
      bullets.forEach(bullet => bullet.classList.remove('active'));
      bullets[current].classList.add('active');
    }

    function initListener() {
      document.querySelector('.slider-pagination-container').addEventListener('click', e => {
        const index = Number(e.target.getAttribute('data-goto'));
        headerSlider.goTo(index);
      });
    }

    (function autoslide() {
      return setTimeout(() => {
        headerSlider.next();
        setTimeout(autoslide(), 5000);
      }, 5000);
    })();
  })();
}

/**
 * Navigation Active Class
 */
(() => {
  const activeNavIndex = Number(document.querySelector('.hidden--nav-index').textContent.trim());
  const navItems = document.querySelectorAll('.header .nav a');
  navItems[activeNavIndex].classList.add('active');
})();

/**
 * Navigation Hover Class
 */
(() => {
  document.querySelectorAll('.header .nav a').forEach(link => {
    link.addEventListener('mouseover', () => {
      link.classList.add('once-hovered');
    }, {
      once: true
    });
  });
  document.querySelectorAll('.footer-nav a').forEach(link => {
    link.addEventListener('mouseover', () => {
      link.classList.add('once-hovered');
    }, {
      once: true
    });
  });
})();

/**
 * Item Slider Init
 */
(() => {
  if (document.querySelector('.item-slider')) {

    function setHeight(slideIndex) {
      const currentElem = document.querySelector('.item-slider').querySelectorAll('.slide')[slideIndex].querySelector('img');
      const height = window.getComputedStyle(currentElem).getPropertyValue('height');
      document.querySelector('.item-slider').style.height = height;
      if (window.innerWidth > 600) {
        let minimum = Number(height.split('').filter(x => x === '.' || !isNaN(+x)).join(''));
        minimum < 270 ?
        document.querySelector('.item-attrs').style.height = 270 + 'px' :
        document.querySelector('.item-attrs').style.height = height;
      } 
    }

    function printSlideIndex () {
      document.querySelector('#cur').innerHTML = this.currentSlide + 1;
      setHeight(this.currentSlide + 1);
    }
    
    const slider = new Siema({
      selector: '.siema',
      loop: true,
      onChange: printSlideIndex
    });
    
    const sliderElem = document.getElementById('siema');
    
    const counter = sliderElem.appendChild(document.createElement('div'));
    counter.classList.add('counter');
    counter.innerHTML = `
      <span id="cur">1</span><span>/</span><span id="all"></span>
    `;
    document.querySelector('#all').innerText = document.querySelectorAll('.slide').length - 2;
    
    const sPrevButton = sliderElem.appendChild(document.createElement('div'));
    sPrevButton.classList.add('s-control');
    sPrevButton.classList.add('prev');
    
    const sNextButton = sliderElem.appendChild(document.createElement('div'));
    sNextButton.classList.add('s-control');
    sNextButton.classList.add('next');
    
    sPrevButton.addEventListener('click', () => slider.prev());
    sNextButton.addEventListener('click', () => slider.next());
   
    const goBackButton = document.body.appendChild(document.createElement('div'));
    goBackButton.classList.add('go-back-btn');
    goBackButton.addEventListener('click', () => history.back());

    window.setSliderHeight = setHeight;
  }
})();

/**
 * Top Offers at Index Page
 */
(() => {
  if (location.pathname === '/' || location.pathname === '/index.html') {
    async function addTopOffersToIndexPage() {
      let html;
      let container = document.getElementById('top-offers');

      await fetch('./offers.html')
        .then(res => res.text())
        .then(data => {
          html = data;
        });
    
      await (() => {
        let list = html.split('<ul class="ad-list">')[1]
          .split('</ul>')[0]
          .split('<li>')
          .map(a => a.split('</li>')[0].trim())
          .filter(x => x !== "");
        
        const rand1 = Math.floor(Math.random() * list.length);
        const rand2 = Math.floor(Math.random() * list.length);

        container.innerHTML += `
          ${list[rand1]}
          ${list[rand2]}
        `;
         
        return list;
      })();   
          
    };
    window.addTopOffersToIndexPage = addTopOffersToIndexPage;
  }
  
})();

/**
 * Mobile Navigation 
 */
(() => {
  const menuButton = document.querySelector('#menu-button');

  document.body.addEventListener('click', e => {
    if (e.target.getAttribute('data-menu') === 'open') {
      e.preventDefault();
      changeAttributes('close');
      toggleMenu(true);
      menuButton.classList.toggle('on-menu');
    } else if (e.target.getAttribute('data-menu') === 'close') {
      e.preventDefault();
      changeAttributes('open');
      toggleMenu(false);
      menuButton.classList.toggle('on-menu');
    }
  });

  function changeAttributes(attribute) {
    const needed = document.querySelectorAll('[data-menu]');
    needed.forEach(elem => elem.setAttribute('data-menu', attribute));
  }

  function toggleMenu(state) {
    const nav = document.querySelector('.mobile-nav');
    state ? nav.classList.add('on-menu') : nav.classList.remove('on-menu');
  }
})();
